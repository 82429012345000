import { FC, useContext  } from 'react';
import { screenStyles } from 'core/constants/styles';
import { Link, NavLink } from 'react-router-dom';
import { SettingContext } from 'core/context/SettingContext';
import { FiArrowRight } from 'react-icons/fi';
import { cities } from './cities';
import { CityType } from './types';

export const TopCities: FC = () => {
  const { setLocation } = useContext(SettingContext);

  const handleNavigate = (city: CityType) => {
    const fullName = `${city.name}, ${city.stateShort}`;

    const locationData = {
      label: fullName,
      obj: {
        lat: city.lat,
        lng: city.lng,
        name: fullName
      }
    };
    setLocation({ ...locationData, userInput: true });
    localStorage.setItem('Location', JSON.stringify(locationData));
  };

  return (
    <section className="max-w-mxWidth mx-auto w-full">
      <div className={screenStyles.mContain}>
      <div className="flex flex-col items-start w-full justify-center">
        <div className="flex justify-between items-center w-full">
          <h2 className="text-center text-darkGreen text-22 sm:text-32 font-bold font-montserrat  pt-32 pb-32 md:pb-48">
          Top Cities for Trailer Rentals
          </h2>
          <NavLink to="/trailer-rentals/locations" className="hidden md:flex items-center !text-darkGreen text-16 font-medium">
            All Rental Locations
            <FiArrowRight className="ml-10" />
          </NavLink>
        </div>
        <div className="grid grid-cols-1 mlg:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 items-start justify-start flex-wrap gap-16 w-full">
          {cities.map((city, index) => (
            city.active && (
              <Link
              key={index}
              className="relative h-190 overflow-hidden cursor-pointer"
              to={`/trailer-rentals${city.path}`}
              onClick={() => handleNavigate(city)}
            >
              <img
                src={city.image}
                loading="lazy"
                alt={`Trailer Rentals in ${city.name}, ${city.stateShort}`}
                className="w-full h-full rounded-8 object-cover"
              />
              <div className="absolute bottom-0 right-0 left-0 bg-headerGradient w-full h-full rounded-8">
                <p
                  className="absolute left-2 bottom-2 text-white font-bold font-montserrat text-base"
                >
                  {`${city.name}, ${city.stateShort} `}
                </p>
              </div>
            </Link>
            )
          ))}
        </div>
        <div className="md:hidden flex flex-col items-center justify-center font-montserrat mt-32 w-full">
          <p className="font-semibold text-base text-blackCow leading-7">Do you want to see all the locations?</p>
          <NavLink to="/trailer-rentals/locations" className="flex items-center text-darkGreen font-semibold text-base">
            All Rental Locations
            <FiArrowRight className="ml-10" />
          </NavLink>
        </div>
      </div>
      </div>
    </section>
  );
}
