import { useEffect, useState } from "react";

export function useIsImageUrlLoaded(src?: string) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (
      !src ||
      src.substring(0,5) === 'data:'
    ) { return; }

    var newImg = new Image();

    newImg.onload = function() {
      setIsLoaded(() => true);
    }

    newImg.src = src;
  }, [src]);

  return isLoaded;
}
