import { useRef, useState } from 'react';
import { Calendar, DateLabel } from 'components';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { dayjsifyFilterDate, formatWeekDay, setFilterDates } from 'utils';
import dayjs from 'dayjs';

export function MobileSearchCalendar() {
  const currentDateRef = useRef(dayjs());
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const filterDate = useSelector((state: any) => state.setting.filterDate);
  const pickupDateStr = filterDate?.pickupDate;
  const returnDateStr = filterDate?.returnDate;

  const onChangeHandler = (dates: [Date | null, Date | null]) => {
    setFilterDates({
      ...filterDate,
      pickupDate: dates?.[0] || undefined,
      returnDate: dates?.[1] || undefined,
    });
  };

  const onSelectHandler = (date: Date | null) => {
    if (!pickupDateStr && date) {
      setFilterDates({ ...filterDate, pickupDate: date });
    }
  }

  const handleClickOnDates = () => {
    setIsCalendarModalOpen(() => true);
  }

  const minDate = currentDateRef.current.toDate();
  const maxDate = currentDateRef.current.add(1, 'year').toDate();

  return (
    <>
      <div
        role="button"
        className="flex rounded-full border px-10 py-10 mt-10"
        onClick={handleClickOnDates}
      >
        <DateLabel>{pickupDateStr}</DateLabel>
        <DateLabel>{returnDateStr}</DateLabel>
      </div>
      <Modal
        className="calendar-mobile !m-0 !max-w-[100vw] [&>div]:w-[100%] [&>div]:h-[100%]"
        centered
        open={isCalendarModalOpen}
        onCancel={() => setIsCalendarModalOpen(false)}
        footer={null}
      >
        <div className="my-20">
          <h3 className="text-24 font-bold text-darkGreen mb-20">Dates</h3>
          <Calendar
            calendarClassName='flex-col'
            startDate={pickupDateStr ? dayjsifyFilterDate(pickupDateStr).toDate() : undefined}
            endDate={returnDateStr ? dayjsifyFilterDate(returnDateStr).toDate() : undefined}
            minDate={minDate}
            maxDate={maxDate}
            openToDate={currentDateRef.current.toDate() || undefined}
            onChange={onChangeHandler}
            onSelect={onSelectHandler}
            formatWeekDay={formatWeekDay}
            monthsShown={12}
            selected={null}
            selectsDisabledDaysInRange={false}
            selectsRange={true}
            inline={true}
            renderCustomHeader={({ monthDate }: { monthDate: Date }) => (
              <span className="react-datepicker__current-month">
                {
                  monthDate.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric",
                  })
                }
              </span>
            )}
          />
        </div>
      </Modal>
    </>
  );
}
