import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Calendar, DateInputs, CalendarPopper } from 'components';
import { useSelector } from 'react-redux';
import { dayjsifyFilterDate, formatWeekDay, isDateBeforeToday, setFilterDates } from 'utils';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

export interface DesktopSearchCalendarProps {
  inputWrapperClassName?: string;
  pickupInputClassName?: string;
  returnInputClassName?: string;
}

export function DesktopSearchCalendar({
  inputWrapperClassName,
  pickupInputClassName,
  returnInputClassName,
}: DesktopSearchCalendarProps) {
  const currentDateRef = useRef(dayjs());
  const [open, setOpen] = useState(false);

  const filterDate = useSelector((state: any) => state.setting.filterDate);

  const pickupDateStr = filterDate?.pickupDate;
  const returnDateStr = filterDate?.returnDate;
  const pickupDate = pickupDateStr ? dayjsifyFilterDate(pickupDateStr).toDate() : undefined;
  const returnDate = returnDateStr ? dayjsifyFilterDate(returnDateStr).toDate() : undefined;

  const [pickupValue, setPickupValue] = useState(pickupDateStr);
  const [returnValue, setReturnValue] = useState(returnDateStr);

  const closeCalendar = () => setOpen(() => false);

  const onChangeHandler = (dates: [Date | null, Date | null]) => {
    setFilterDates({
      ...filterDate,
      pickupDate: dates?.[0] || undefined,
      returnDate: dates?.[1] || undefined,
    });
    if (dates?.[1]) {
      closeCalendar();
    }
  };

  const onSelectHandler = (date: Date | null) => {
    if (!pickupDateStr && date) {
      setFilterDates({ ...filterDate, pickupDate: date });
    }
  }

  const onPickupChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPickupValue(() => evt.target.value);
  }

  const onPickupKeyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key !== 'Enter') { return; }

    const pickupStr = (evt.target as HTMLInputElement).value;

    if (!dayjs(pickupStr).isValid() || isDateBeforeToday(pickupStr)) {
      setPickupValue(() => pickupDateStr);
      return;
    }

    setFilterDates({
      ...filterDate,
      pickupDate: pickupStr,
    });
  }

  const onReturnChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setReturnValue(() => evt.target.value);
  }

  const onReturnKeyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key !== 'Enter') { return; }

    const returnStr = (evt.target as HTMLInputElement).value;

    if (!dayjs(returnStr).isValid() || isDateBeforeToday(returnStr)) {
      setPickupValue(() => pickupDateStr);
      return;
    }

    setFilterDates({
      ...filterDate,
      returnDate: returnStr,
    });
  }

  const resetDates = () => {
    setFilterDates({
      ...filterDate,
      pickupDate: '',
      returnDate: '',
    });
  };

  useEffect(() => {
    setPickupValue(() => pickupDateStr);
  }, [pickupDateStr])

  useEffect(() => {
    setReturnValue(() => returnDateStr);
  }, [returnDateStr])

  const minDate = currentDateRef.current;

  return (
    <CalendarPopper
      open={open}
      onClose={closeCalendar}
      placement="bottom"
      content={(
        <Calendar
          inline
          startDate={pickupDate}
          endDate={returnDate}
          onChange={onChangeHandler}
          onSelect={onSelectHandler}
          formatWeekDay={formatWeekDay}
          monthsShown={2}
          selectsDisabledDaysInRange={false}
          selectsRange={true}
          minDate={minDate.toDate()}
          selected={pickupDate && !returnDate ? pickupDate : null}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
            nextMonthButtonDisabled,
            prevMonthButtonDisabled,
          }: ReactDatePickerCustomHeaderProps) => (
            <div className='flex justify-between items-center w-full'>
              <button
                aria-label="Previous Month"
                className="py-8 px-16"
                style={{
                  visibility: customHeaderCount === 1
                    || prevMonthButtonDisabled
                    || dayjs(monthDate).startOf('month').isSame(minDate.startOf('month'))
                      ? 'hidden' : 'visible'
                }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                { //@ts-ignore
                  <LeftOutlined />
                }
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className="py-8 px-16"
                style={{
                  visibility: customHeaderCount === 0 || nextMonthButtonDisabled
                    ? 'hidden' : 'visible'
                }}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                { //@ts-ignore
                  <RightOutlined />
                }
              </button>
            </div>
          )}
        />
      )}
    >
      <div
        role="button"
        className={`flex relative items-center justify-stretch group ${inputWrapperClassName}`}
        onClick={() => setOpen(() => true)}
      >
        <DateInputs
          pickupInputProps={{
            value: pickupValue,
            name: 'pickupInput',
            onChange: onPickupChangeHandler,
            onKeyDown: onPickupKeyDownHandler,
            className: pickupInputClassName,
          }}
          returnInputProps={{
            value: returnValue,
            name: 'returnInput',
            onChange: onReturnChangeHandler,
            onKeyDown: onReturnKeyDownHandler,
            className: returnInputClassName,
          }}
        />
        <CloseCircleOutlined
          className="absolute right-0 invisible group-hover:visible"
          onClick={resetDates}
        />
      </div>
    </CalendarPopper>
  );
}
