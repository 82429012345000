/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import type { cardProp, equipmentItemProp, industryCardProp, IProductCategory } from '../interfaces/base';

import cardImg1 from '../../assets/images/products/product1.png';
import cardImg2 from '../../assets/images/products/product2.png';
import cardImg3 from '../../assets/images/products/product3.png';
import cardImg4 from '../../assets/images/products/product4.png';
import cardImg5 from '../../assets/images/products/product5.png';
import cardImg6 from '../../assets/images/products/product6.png';
import carHaulerImg from '../../assets/images/products/car-hauler.png';
import deckoverImg from '../../assets/images/products/deckover.png';
import unitedRentalLogo from '../../assets/images/companies/united-rentals.png';
import heEquipmentLogo from '../../assets/images/companies/equipment-services.png';
import laxLogo from '../../assets/images/companies/lax.png';

export const cardItems: cardProp[] = [
  {
    popular: true,
    title: 'Warehouse Forklifts',
    image: cardImg1,
    type: 'explore',
  },
  {
    popular: true,
    title: 'Heavy Duty Forklifts',
    image: cardImg2,
    type: 'explore',
  },
  {
    popular: true,
    title: 'Rough Terrain Forklifts',
    image: cardImg3,
    type: 'explore',
  },
  {
    popular: false,
    title: 'Telehandlers',
    image: cardImg4,
    type: 'explore',
  },
  {
    popular: false,
    title: 'Pallet Jacks',
    image: cardImg5,
    type: 'explore',
  },
  {
    popular: false,
    title: 'Walkie Stackers',
    image: cardImg6,
    type: 'explore',
  },
];

export const bestCardItems: cardProp[] = [
  {
    popular: true,
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    subTitle: 'Equipment ID: 11-4',
    image: cardImg1,
    type: 'add',
    details: [
      'Maximum lift capacity up to 15.5 tons',
      'Pneumatic tires',
      'Grade range up to 30% under full load',
      'Maximum fork height 9\'8\"',
      'Add this forklift to your cart now',
    ],
  },
  {
    popular: true,
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    subTitle: 'Equipment ID: 11-4',
    image: cardImg1,
    type: 'add',
    details: [
      'Maximum lift capacity up to 15.5 tons',
      'Pneumatic tires',
      'Grade range up to 30% under full load',
      'Maximum fork height 9\'8\"',
      'Add this forklift to your cart now',
    ],
  },
  {
    popular: false,
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    subTitle: 'Equipment ID: 11-4',
    image: cardImg1,
    type: 'add',
    details: [
      'Maximum lift capacity up to 15.5 tons',
      'Pneumatic tires',
      'Grade range up to 30% under full load',
      'Maximum fork height 9\'8\"',
      'Add this forklift to your cart now',
    ],
  },
];

export const equipmentItems: equipmentItemProp[] = [
  {
    popular: false,
    equipId: 'Toyota THDE3500-24',
    title: '4000 lbs Cushion Tire Forklift',
    image: cardImg1,
    price: {
      initialPrice: 250,
      salePrice: 205,
      priceDate: 125,
      priceWeek: 445,
      priceMonth: 1225,
      savePercent: 18,
    },
    company: unitedRentalLogo,
  },
  {
    popular: true,
    equipId: 'Toyota THDE3000-24',
    title: '25,000 lbs Cushion Tire Forklift',
    image: cardImg1,
    price: {
      initialPrice: 250,
      salePrice: 205,
      priceDate: 1482,
      priceWeek: 4201,
      priceMonth: 13012,
      savePercent: 18,
    },
    company: heEquipmentLogo,
  },
  {
    popular: false,
    equipId: 'Titan TD25RT4',
    title: '5000 lbs Rough Terrain Forklifts',
    image: cardImg1,
    price: {
      initialPrice: 200,
      salePrice: 164,
      priceDate: 165,
      priceWeek: 667,
      priceMonth: 2100,
      savePercent: 18,
    },
    company: unitedRentalLogo,
  },
  {
    popular: false,
    equipId: 'THDC2500-30',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg1,
    price: {
      initialPrice: 200,
      salePrice: 180,
      priceDate: 320,
      priceWeek: 950,
      priceMonth: 2350,
      savePercent: 10,
    },
    company: laxLogo,
  },
];

export const reviewItems: cardProp[] = [
  {
    popular: false,
    title: '25,000 lbs Cushion Tire Forklift',
    subTitle: 'Toyota THDE3500-24',
    image: cardImg1,
    type: 'add',
  },
  {
    popular: false,
    title: '25,000 lbs Cushion Tire Forklift',
    subTitle: 'Toyota THDE3000-24',
    image: cardImg1,
    type: 'add',
  },
  {
    popular: false,
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    subTitle: 'Toyota THDC3000-36',
    image: cardImg1,
    type: 'add',
  },
];

export const addonItems: equipmentItemProp[] = [
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg1,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    company: unitedRentalLogo,
  },
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg2,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    company: heEquipmentLogo,
  },
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg3,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    company: laxLogo,
  },
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg4,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    company: unitedRentalLogo,
  },
];

export const cartItems: equipmentItemProp[] = [
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg1,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    count: 1,
    company: unitedRentalLogo,
  },
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg2,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    count: 1,
    company: heEquipmentLogo,
  },
  {
    popular: true,
    equipId: 'Equipment ID: 11-4',
    title: '5000 lbs Warehouse Forklift Cushion Tire',
    image: cardImg3,
    price: {
      initialPrice: 400,
      salePrice: 200,
      priceDate: 112,
      priceWeek: 200,
      priceMonth: 402,
      savePercent: 20,
    },
    count: 1,
    company: laxLogo,
  },
];

export const industryCards: industryCardProp[] = [
  {
    title: 'Sample Title',
    img: null,
    content: 'Sample content',
  },
];

export const productCategories: {[key:string]: IProductCategory} = {
  'trailers': {
    id: '',
    label: 'Trailers',
    directory: 'trailers',
  },
  'compact-equipment': {
    id: '',
    label: 'Compact Equipment',
    directory: 'compact-equipment',
  },
  'aerial-lifts': {
    id: '',
    label: 'Aerial Lifts',
    directory: 'aerial-lifts',
  },
  'waste-management': {
    id: '',
    label: 'Waste Management',
    directory: 'waste-management',
  },
  'material-handling': {
    id: '',
    label: 'Material Handling',
    directory: 'material-handling',
  },
  // 'site-services': {
  //     id: '',
  //     label: "Site Services",
  //     directory: "site-services"
  // },
  // 'compaction': {
  //   id: '',
  //   label: 'Compaction',
  //   directory: 'compaction',
  // },
  // 'heavy-earthmoving': {
  //   id: '',
  //   label: 'Heavy Earthmoving',
  //   directory: 'heavy-earthmoving',
  // },
};

export const productSubCategories:{[key:string]: { [key:string]: IProductCategory} } = {
  'material-handling': {
    'warehouse-forklifts': {
      id: '',
      label: 'Warehouse Forklifts',
      directory: 'warehouse-forklifts',
      image: cardImg1,
    },
    'heavy-duty-forklifts': {
      id: '',
      label: 'Heavy Duty Forklifts',
      directory: 'heavy-duty-forklifts',
      image: cardImg2,
    },
    'rough-terrain-forklifts': {
      id: '',
      label: 'Rough Terrain Forklifts',
      directory: 'rough-terrain-forklifts',
      image: cardImg3,
    },
    'telehandlers': {
      id: '',
      label: 'Telehandlers',
      directory: 'telehandlers',
      image: cardImg4,
    },
    'pallet-jacks': {
      id: '',
      label: 'Pallet Jacks',
      directory: 'pallet-jacks',
      image: cardImg5,
    },
    'walkie-stackers': {
      id: '',
      label: 'Walkie Stackers',
      directory: 'walkie-stackers',
      image: cardImg6,
    },
  },
  'aerial-lifts': {
    'scissor-lifts': {
      id: '',
      label: 'Scissor Lifts',
      directory: 'scissor-lifts',
      image: cardImg2,
    },
    'articulating-boom-lifts': {
      id: '',
      label: 'Articulating Boom Lifts',
      directory: 'articulating-boom-lifts',
      image: cardImg3,
    },
    'straight-boom-lifts': {
      id: '',
      label: 'Straight Boom Lifts',
      directory: 'straight-boom-lifts',
      image: cardImg4,
    },
    'towable-boom-lifts': {
      id: '',
      label: 'Towable Boom Lifts',
      directory: 'towable-boom-lifts',
      image: cardImg5,
    },
    'telehandlers': {
      id: '',
      label: 'Telehandler',
      directory: 'telehandlers',
      image: cardImg6,
    },
  },
  'trailers': {
    'enclosed-trailers': {
      id: '',
      label: 'Enclosed Trailers',
      directory: 'enclosed-trailers',
      image: cardImg4,
      url: '/trailer-rentals/results?type=enclosed',
      description: 'Perfect for moving, vehicles, cargo, and versatile hauling needs.'
    },
    'flatbed-trailers': {
      id: '',
      label: 'Flatbed Trailers',
      directory: 'flatbed-trailers',
      image: cardImg1,
      url: '/trailer-rentals/results?type=flatbed',
      description: 'Ideal for hauling cars, ATVs, equipment, and recreational vehicles.'
    },
    'car-hauler-trailers': {
      id: '',
      label: 'Car Hauler Trailers',
      directory: 'car-hauler-trailers',
      image: carHaulerImg,
      url: '/trailer-rentals/results?type=car-hauler',
      description: 'Ideal for hauling cars, ATVs, equipment, and recreational vehicles.'
    },
    'utility-trailers': {
      id: '',
      label: 'Utility Trailers',
      directory: 'utility-trailers',
      image: cardImg1,
      url: '/trailer-rentals/results?type=utility',
      description: 'Versatile utility trailers for everyday hauling and general use.'
    },
    'equipment-trailers': {
      id: '',
      label: 'Equipment Trailers',
      directory: 'equipment-trailers',
      image: cardImg6,
      url: '/trailer-rentals/results?type=equipment',
      description: 'Haul equipment with trailers designed for projects and construction.',
    },
    'deckover-trailers': {
      id: '',
      label: 'Deckover Trailers',
      directory: 'deckover-trailers',
      image: deckoverImg,
      url: '/trailer-rentals/results?type=deckover',
      description: 'Deckover trailers provide extra space for wide loads and large equipment.'
    },
    'tilt-trailers': {
      id: '',
      label: 'Tilt Deck Trailers',
      directory: 'tilt-trailers',
      image: cardImg1,
      url: '/trailer-rentals/results?type=tilt',
      description: 'Easy loading and unloading with tilt trailers for vehicles and equipment.'
    },
    'gooseneck-trailers': {
      id: '',
      label: 'Gooseneck Trailers',
      directory: 'gooseneck-trailers',
      image: cardImg2,
      url: '/trailer-rentals/results?type=gooseneck',
      description: 'Heavy-duty gooseneck trailers for stability and maximum payload capacity.'
    },
    'dump-trailers': {
      id: '',
      label: 'Dump Trailers',
      directory: 'dump-trailers',
      image: cardImg1,
      url: '/trailer-rentals/results?type=dump',
      description: 'Efficient dump trailers for quick material handling and unloading.'
    },
    'roll-off-dump-trailers': {
      id: '',
      label: 'Roll-Off Dump Trailers',
      directory: 'roll-off-dump-trailers',
      image: cardImg1,
      url: '/trailer-rentals/results?type=roll-off',
      description: 'Flexible roll-off trailers for construction, landscaping, and cleanup.',
    },
  },
  'siteServices': {
    'storage-containers': {
      id: '',
      label: 'Storage Containers',
      directory: 'storage-containers',
      image: cardImg1,
    },
  },
  'compact-equipment': {
    'compact-track-loaders': {
      id: '',
      label: 'Compact Track Loaders',
      directory: 'compact-track-loaders',
      image: cardImg1,
    },
    'wheeled-skid-steers': {
      id: '',
      label: 'Wheeled Skid Steers',
      directory: 'wheeled-skid-steers',
      image: cardImg2,
    },
    'mini-excavators': {
      id: '',
      label: 'Mini Excavators',
      directory: 'mini-excavators',
      image: cardImg3,
    },
    /* 'backhoes': {
      id: '',
      label: 'Backhoes',
      directory: 'backhoes',
      image: cardImg4,
    },
    'tractors': {
      id: '',
      label: 'Tractors',
      directory: 'tractors',
      image: cardImg5,
    }, */
  },
  'heavy-earthmoving': {
    'excavators': {
      id: '',
      label: 'Excavators',
      directory: 'excavators',
      image: cardImg4,
    },
    'wheel-loaders': {
      id: '',
      label: 'Wheel Loaders',
      directory: 'wheel-loaders',
      image: cardImg3,
    },
    'dozers': {
      id: '',
      label: 'Dozers',
      directory: 'dozers',
      image: cardImg5,
    },
    'rock-trucks': {
      id: '',
      label: 'Rock Trucks',
      directory: 'rock-trucks',
      image: cardImg6,
    },
  },
  'compaction': {
    'soil-compaction-smooth-drum': {
      id: '',
      label: 'Soil Compaction Smooth Drum',
      directory: 'soil-compaction-smooth-drum',
      image: cardImg3,
    },
    'soil-compaction-pad-foot': {
      id: '',
      label: 'Soil Compaction Pad Foot',
      directory: 'soil-compaction-pad-foot',
      image: cardImg5,
    },
    'asphalt-compaction-double-drum': {
      id: '',
      label: 'Asphalt Compaction Double Drum',
      directory: 'asphalt-compaction-double-drum',
      image: cardImg6,
    },
  },
  'waste-management': {
    'rolloff-dumpsters': {
      id: '',
      label: 'Roll Off Dumpsters',
      directory: 'rolloff-dumpsters',
      image: cardImg1,
    },
    'trash-bins': {
      id: '',
      label: 'Trash Bins',
      directory: 'trash-bins',
      image: cardImg2,
    },
  },
};
