import { configureStore } from '@reduxjs/toolkit';

import settingSlice from './slices/settingSlice';

const store = configureStore({
  reducer: {
    setting: settingSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppState = ReturnType<typeof store.getState>;

export default store;
