/** @type {import('tailwindcss').Config} */

function range(start, end, increment = 1) {
  const count = Math.floor((end - start + 1) / increment);
  return Array(count).fill(0).map((_, idx) => start + idx * increment);
}

const minFontSize = 5;
const maxFontSize = 140;

const minSpacingPixel = 0;
const maxSpacingPixel = 1000;
const spacingPixelIncrement = 5;

const config = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: 'class',
  theme: {
    extend: {
      spacing: {
        0: '0px',
        'calc-half-gap': 'calc((100vw - 1440px) / 2)',
        ...range(minSpacingPixel, maxSpacingPixel, spacingPixelIncrement).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {}),
      },
      fontFamily: {
        poppins: "'Poppins', sans-serif",
        montserrat: ["Montserrat", "sans-serif"],
        jakarta : ["Plus Jakarta Sans", "sans-serif"],
        dmSans : ["DM Sans", "sans-serif"],
        fraunces : ["Fraunces"],
        roboto: ["Roboto", "sans-serif"],
      },
      fontSize: {
        ...range(minFontSize, maxFontSize).reduce((merged, f) => ({ ...merged, [f]: `${f}px`}), {}),
        ...range(1, 50).reduce((merged, f) => ({ ...merged, [`${f*0.1}em`]: `${f*0.1}em`}), {}),
      },
      fontWeight: {
        thin: '100',
        extralight: '200',
        light: '300',
        normal: '400',
        medium: '500',
        semibold: '600',
        bold: '700',
        extrabold: '800',
        black: '900',
      },
      letterSpacing: {
        4: '4px',
        ...range(1, 100).reduce((merged, f) => ({ ...merged, [`${f}p`]: `${f*0.01}em`}), {}),
      },
      lineHeight:{
        ...range(1, 100).reduce((merged, f) => ({ ...merged, [`${f*0.1}em`]: `${f*0.1}em`}), {}),
      },
      borderRadius: {
        none: '0px',
        sm: '0.125rem',
        DEFAULT: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
        full: '9999px',
        ...range(1, 40).reduce((merged, f) => ({ ...merged, [f]: `${f}px`}), {}),
      },
      borderWidth: {
        1: '1px',
        0: '0px',
        2: '2px',
        3: '3px',
        4: '4px',
        8: '8px',
      },
      padding: {
        0: '0px',
        ...range(minSpacingPixel, 100).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {})
      },
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        brown: '#512C29',
        darkGreen: '#1D1D1D',
        charcoalgrey: 'rgba(255,255,255,0.24)',
        lemonGrass: 'rgba(255,255,255,0.6)',
        blackCow: '#4A4A4A',
        supernova: '#F6CC08',
        'supernova-light': '#F6CC0850',
        'supernova-dark': '#AC8E05',
        yellowBrown: '#AC8F06',
        brulee: '#FBEA9A',
        softpeach: '#EDEDED',
        wSmoke: '#F5F5F5', //white smoke
        dune: '#343434',
        boulder: '#777777',
        desertStorm: '#F8F8F8',
        greyCloud: '##B7B7B7',
        beanRed: '#FE585B',
        pearl: '#FFEEEF',
        chatamas: '#115C77',
        vivid: '#0C37F7',
        graydark: '#333A48',
        lightDove: '#F1F1F1',
        deepMocha : '#1E1D1C',
        urbanGray: '#828282',
        midnightSlate: '#25282C',
        neutral: {
          2: '#FAFAFA',
          3: '#F8F8F8',
          8: '#C9C9C9',
          24: '#E0E0E0',
          32: '#B7B7B7',
          40: '#A5A5A5',
          60: '#777777',
          80: '#4A4A4A',
          90: '#343434',
          100: '#1D1D1D'
        },
        info: {
          main : '#0099FF'
        }
      },
      boxShadow: {
        default: '5px 10px 40px -5px rgba(0, 0, 0, 0.08)',
        dune: '2px 5px 10px -2px rgba(52, 52, 52, 1)'
      },
      margin:{
        ...range(0, 100).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {}),
        160: '160px'
      },
      gap:{
        ...range(0, 100).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {}),
        160: '160px'
      },
      screens: {
        msm: '320px',
        mmd: '375px',
        mlg: '425px',
        sm: '680px',
        lap: '890px',
        md: '960px',
        lg: '1280px',
        lgAd: '1440px',
        xl: '1920px',
      },
      maxWidth: {
        ...range(minSpacingPixel, maxSpacingPixel, spacingPixelIncrement).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {}),
        mxWidth: '1440px'
      },
      minWidth: {
        ...range(minSpacingPixel, maxSpacingPixel, spacingPixelIncrement).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {})
      },
      minHeight: {
        ...range(minSpacingPixel, maxSpacingPixel, spacingPixelIncrement).reduce((merged, f) => ({ ...merged, [f]: `${f}px` }), {})
      },
      zIndex: {
        ...range(minSpacingPixel, maxSpacingPixel, spacingPixelIncrement).reduce((merged, f) => ({ ...merged, [f]: `${f}` }), {})
      },
      backgroundImage: {
        'cover' : "url('./assets/images/header4.png')",
        'headerGradient' : "linear-gradient(to bottom,rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0) 100%)"
      },
      brightness: {
        25: '.25',
        30: '.30',
        175: '1.75',
      },
      animation: {
        show: 'displayblack 0.5s',
      },
      keyframes: {
        displayblack: {
          '0%': { backgroundColor: 'rgba(0,0,0,0)' },
          '100%': { backgroundColor: 'rgba(29,29,29,1)' },
        }
      }
    },
  },
  plugins: [],
}

export default config;
