import qs from 'qs';
import http from '../httpService';
import { ListAssetsProps, MapAssetsProps } from './types';

class AssetsApi {
  async getSimilarAsset(
    assetId: any,
    pickupDate: any,
    returnDate: any,
    assetTypeId: any,
    locationId: any,
    length: any,
    radius: any,
    pageCount: any,
  ) {
    const url = `${process.env.REACT_APP_BASE_URL
    }asset/list/similar?assetId=${assetId}&pickupStr=${pickupDate}&returnStr=${returnDate}&assetTypeId=${assetTypeId}&locationId=${locationId}&length=${length}&radius=${radius}&pageCount=${pageCount}`;

    return http.get(url);
  }

  async getMapAssets(props: MapAssetsProps) {
    return http.get(
      `${process.env.REACT_APP_BASE_URL}asset/list/map${qs.stringify(props, { indices: false, addQueryPrefix: true })}`
    );
  }

  async getListAssets(props: ListAssetsProps) {
    return http.get(
      `${process.env.REACT_APP_BASE_URL}asset/search${qs.stringify(props, { indices: false, addQueryPrefix: true })}`
    );
  }

  async getAssetRate(assetId: any) {
    const config = {
    };

    return http.get(
      `${process.env.REACT_APP_BASE_URL
      }asset/${assetId}/rate`,
      config,
    );
  }

  async getBookingFee(
    assetID:any,
    pickupDate:any,
    pickupTime: any,
    returnDate: any,
    returnTime: any,
    promoCode: any = "",
  ) {
    const config = {
    };
    const bookingFeeData = `?assetId=${assetID}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&returnDate=${returnDate}&returnTime=${returnTime}&promoCode=${promoCode}`; // &surcharge=$Asset_Surcharge
    return http.get(`${process.env.REACT_APP_BASE_URL}booking/bill/fee${bookingFeeData}`, config);
  }

  async getAssetDetail(assetId: any) {
    const config = {};

    return http.get(`${process.env.REACT_APP_BASE_URL
    }asset/${assetId}/detail`, config);
  }

  async getAssetCalendar(
    assetId: string,
    pickupDate = '',
    pickupTime = '12:00',
    returnDate = '',
    returnTime = '12:00',
  ) {
    const url = `${process.env.REACT_APP_BASE_URL
    }asset/${assetId}/calendar?pickupDate=${pickupDate}&pickupTime=${pickupTime}&returnDate=${returnDate}&returnTime=${returnTime}`;

    return http.get(url);
  }

  async getAssetAvail(
    assetId: string,
    pickupDate = '',
    pickupTime = '12:00',
    returnDate = '',
    returnTime = '12:00',
  ) {
    const url = `${process.env.REACT_APP_BASE_URL
    }asset/${assetId}/available?pickupDate=${pickupDate}&pickupTime=${pickupTime}&returnDate=${returnDate}&returnTime=${returnTime}`;

    return http.get(url);
  }
}

export const assetsApi = new AssetsApi();
