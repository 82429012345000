/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { chooseIndustryProp } from '../interfaces/base';

export interface IMenuProp {
  label: string;
  key: string;
  value?: string;
  url?: string;
  lookup?:string;
}

export const menu_languages: IMenuProp[] = [
  {
    label: 'ENG',
    key: '1',
    value: '1',
  },
];
export const menu_trailers: IMenuProp[] = [
  {
    label: 'All Trailers',
    key: 'allTrailers',
    lookup: '',
    value: '',
    url: '/trailer-rentals',
  },
  {
    label: 'Enclosed Trailers',
    key: 'enclosedTrilers',
    lookup: 'enclosed',
    value: '10',
    url: '/trailer-rentals/results?type=enclosed',
  },
  {
    label: 'Flatbed Trailers',
    key: 'flatbedTrailers',
    lookup: 'flatbed',
    value: '11',
    url: '/trailer-rentals/results?type=flatbed',
  },
  {
    label: 'Car Hauler Trailers',
    key: 'carHaulerTrailers',
    lookup: 'car-hauler',
    value: '22',
    url: '/trailer-rentals/results?type=car-hauler',
  },
  {
    label: 'Utility Trailers',
    key: 'utilityTrailers',
    lookup: 'utility',
    value: '16',
    url: '/trailer-rentals/results?type=utility',
  },
  {
    label: 'Equipment Trailers',
    key: 'equipmentTrailers',
    lookup: 'equipment',
    value: '21',
    url: '/trailer-rentals/results?type=equipment',
  },
  {
    label: 'Deckover Trailers',
    key: 'dockoverTrailers',
    lookup: 'deckover',
    value: '17',
    url: '/trailer-rentals/results?type=deckover',
  },
  {
    label: 'Tilt Deck Trailers',
    key: 'tiltDeckTrailers',
    lookup: 'tilt',
    value: '20',
    url: '/trailer-rentals/results?type=tilt',
  },
  {
    label: 'Gooseneck Trailers',
    key: 'gooseneckTrailers',
    lookup: 'gooseneck',
    value: '19',
    url: '/trailer-rentals/results?type=gooseneck',
  },
  {
    label: 'Dump Trailers',
    key: 'dumpTrailers',
    lookup: 'dump',
    value: '15',
    url: '/trailer-rentals/results?type=dump',
  },
  {
    label: 'Roll-Off Dump Trailers',
    key: 'rollOffDumpTrailers',
    lookup: 'roll-off',
    value: '18',
    url: '/trailer-rentals/results?type=roll-off',
  },
];
export const menu_equipments: IMenuProp[] = [
  {
    label: 'All Equipment',
    key: '0',
    url: '/trailer-rentals',
  },
  {
    label: 'Compact Track Loaders',
    key: '1',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Wheeled Skid Steers',
    key: '2',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Mini Excavators',
    key: '3',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Scissor Lifts',
    key: '4',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Articulating Boom Lifts',
    key: '5',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Straight Boom Lifts',
    key: '6',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Towable Boom Lifts',
    key: '7',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Telehandlers',
    key: '8',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Roll Off Dumpsters',
    key: '9',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Trash Bins',
    key: '10',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Warehouse Forklifts',
    key: '11',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Heavy Duty Forklifts',
    key: '12',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Rough Terrain Forklifts',
    key: '13',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Pallet Jacks',
    key: '14',
    url: '/trailer-rentals/results',
  },
  {
    label: 'Walkie Stackers',
    key: '15',
    url: '/trailer-rentals/results',
  },
];
export const menu_profile: IMenuProp[] = [
  {
    label: 'Login',
    key: '1',
  },
  {
    label: 'Sign up',
    key: '2',
  },
  {
    label: 'Checkout',
    key: '3',
    url: '/checkout',
  },
  {
    label: 'How we works',
    key: '4',
  },
  {
    label: 'Trailer FAQs',
    key: '5',
  },
];

export const choose_industries: chooseIndustryProp[] = [
  {
    title: 'Industry',
    url: '',
  },
];
