import { memo } from 'react';
import DatePicker, { type DatePickerProps } from 'react-datepicker';

import classes from './calendar.module.scss';

export type CalendarProps = DatePickerProps & {}

export function Calendar({
  calendarClassName = '',
  popperClassName = '',
  ...rest
}: CalendarProps) {
  return (
    <DatePicker
      calendarClassName={`${classes.calendar} ${calendarClassName}`}
      popperClassName={`${classes.popper} ${popperClassName}`}
      {...rest}
    />
  );
};

export const CalendarMemo = memo(Calendar);
