import http from './httpService';

class PromoApi {
  async getPromotions() {
    const result = await http.get(`${process.env.REACT_APP_BASE_URL ?? ''}promotion/DISCOUNT?portalType=MARKETPLACE`);
    return result;
  }

  async recordPromoRequest(data: any) {
    const result = await http.post(`${process.env.REACT_APP_BASE_URL ?? ''}promotion`, JSON.stringify(data), {
      headers: {
        'accept': 'application/json, text/plain, */*',
        'content-type': 'application/json',
      },
    });
    return result;
  }

}

export const promoApi = new PromoApi();
