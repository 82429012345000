import { useEffect, useState } from "react";

export const useIsSafari = () => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsSafari(userAgent.includes('Safari') && !userAgent.includes('Chrome'));
  }, []);

  return isSafari;
};
