import { Calendar, CalendarPopper, DateInput } from 'components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { dayjsifyFilterDate, formatWeekDay, isDateBeforeToday, setFilterDates } from 'utils';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { CalendarOutlined, CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useGetCalendarInfo } from 'hooks';
import { useEffect, useRef, useState } from 'react';

export interface DesktopSearchSingleCalendarProps {
  type: 'pickup' | 'return';
  inputWrapperClassName?: string;
  inputClassName?: string;
  onDateChange?: (date: Date | null) => void;
  excludeDates?: Date[];
}

export function DesktopSearchSingleCalendar({
  type,
  inputWrapperClassName,
  inputClassName,
  onDateChange,
  excludeDates = [],
}: DesktopSearchSingleCalendarProps) {
  const currentDateRef = useRef(dayjs());
  const [open, setOpen] = useState(false);
  const { assetId } = useParams();

  const filterDate = useSelector((state: any) => state.setting.filterDate);

  const { excludeDates: calendarExcludeDates } = useGetCalendarInfo(assetId || '');

  const isPickup = type === 'pickup';
  const pickupDateStr = filterDate?.pickupDate;
  const returnDateStr = filterDate?.returnDate;
  const pickupDate = pickupDateStr ? dayjsifyFilterDate(pickupDateStr).toDate() : undefined;
  const returnDate = returnDateStr ? dayjsifyFilterDate(returnDateStr).toDate() : undefined;
  const excludedDates = [...excludeDates, ...calendarExcludeDates];

  const [value, setValue] = useState(pickupDateStr);

  const closeCalendar = () => setOpen(() => false);

  const getFilterDateObject = (date: Date | string) => Object.assign(
    {},
    filterDate,
    isPickup ? { pickupDate: date } : { returnDate: date },
  );

  const setFilterDate = (date: Date | string) => {
    setFilterDates(getFilterDateObject(date));
  }

  const onChangeDateHandler = (date: Date | null) => {
    onDateChange?.(getFilterDateObject(date || ''));
    setFilterDate(date || '');
    closeCalendar();
  };

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(() => evt.target.value);
  }

  const onKeyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key !== 'Enter') { return; }

    const dateStr = (evt.target as HTMLInputElement).value;

    if (!dayjs(dateStr).isValid() || isDateBeforeToday(dateStr)) {
      setValue(() => isPickup ? pickupDateStr : returnDateStr);
      return;
    }

    setFilterDate(dateStr);
  }

  const resetDates = () => {
    setFilterDate('');
  };

  useEffect(() => {
    setValue(() => isPickup ? pickupDateStr : returnDateStr);
  }, [pickupDateStr, returnDateStr, isPickup])

  const minDate = currentDateRef.current;

  return (
    <CalendarPopper
      open={open}
      onClose={closeCalendar}
      placement="bottom"
      content={(
        <Calendar
          inline
          onChange={onChangeDateHandler}
          formatWeekDay={formatWeekDay}
          monthsShown={1}
          selectsDisabledDaysInRange
          minDate={(!isPickup && pickupDate) || minDate.toDate()}
          selected={isPickup ? pickupDate : returnDate}
          excludeDates={excludedDates}
          renderCustomHeader={({
            monthDate,
            decreaseMonth,
            increaseMonth,
            nextMonthButtonDisabled,
            prevMonthButtonDisabled,
          }: ReactDatePickerCustomHeaderProps) => (
            <div className='flex justify-between items-center w-full'>
              <button
                aria-label="Previous Month"
                className="py-8 px-16"
                style={{
                  visibility: prevMonthButtonDisabled
                    || dayjs(monthDate).startOf('month').isSame(minDate.startOf('month'))
                      ? 'hidden' : 'visible'
                }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                { //@ts-ignore
                  <LeftOutlined />
                }
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className="py-8 px-16"
                style={{
                  visibility: nextMonthButtonDisabled
                    ? 'hidden' : 'visible'
                }}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                { //@ts-ignore
                  <RightOutlined />
                }
              </button>
            </div>
          )}
        />
      )}
    >
      <div
        role="button"
        className={`flex relative items-center justify-stretch group ${inputWrapperClassName}`}
        onClick={() => setOpen(() => true)}
      >
        <DateInput
          value={value}
          name={type}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          className={inputClassName}
        />
        <CalendarOutlined style={{ color: 'rgb(156 163 175)' }} className="mr-10 group-hover:invisible" />
        <CloseCircleOutlined
          className="absolute right-10 invisible group-hover:visible"
          onClick={resetDates}
        />
      </div>
    </CalendarPopper>
  );
}
