import { TrustBooking, TrustGrowing, TrustTrailers, TrustSupport } from 'components';

export function Trust() {
  return (
    <section className="max-w-mxWidth mx-auto w-full">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 gap-y-40 py-40 md:py-32 mx-16 sm:mx-40 md:mx-60 lg:mx-80 ">
        <TrustGrowing />
        <TrustTrailers />
        <TrustBooking />
        <TrustSupport />
      </div>
    </section>
  );
}
