import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { setAxiosAuth } from 'utils';
import { promoApi } from 'core/api/promo.api';
import { queryKeys } from '../queryKeys';
import type { PromotionResponse } from './types';

export const useGetPromotion = ({ accessToken = '' }) => {
  setAxiosAuth(accessToken);

  return useQuery<AxiosResponse<PromotionResponse>>({
    queryKey: [queryKeys.promotion],
    queryFn: promoApi.getPromotions,
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken),
  });
}
