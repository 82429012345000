import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { assetsApi } from 'core/api';
import { setAxiosAuth } from 'utils';
import { UseGetAssetsProps } from './types';

export const useGetMapAssets = ({
  pickupDate,
  returnDate,
  assetTypeId = '',
  accessToken = '',
  enabled = true,
}: UseGetAssetsProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [
      queryKeys.mapAssets,
      pickupDate,
      returnDate,
      assetTypeId,
    ],
    queryFn: () => assetsApi.getMapAssets({
      pickupDate,
      returnDate,
      assetTypeId,
    }),
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken) && enabled,
  });
}

export const useGetListAssets = ({
  lat,
  lng,
  sort = 'BEST_MATCH',
  sortOrder = 'ASC',
  assetIds = [],
  assetTypeId = '',
  accessToken = '',
  enabled = true,
}: UseGetAssetsProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [
      queryKeys.listAssets,
      assetIds,
      sort,
      sortOrder,
    ],
    queryFn: () => assetsApi.getListAssets({
      lat,
      lng,
      sort,
      sortOrder,
      assetId: assetIds,
      assetTypeId,
    }),
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken) && enabled,
  });
}
