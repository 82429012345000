import qs from 'qs';

export const updateQueryString = (query: { [key: string]: string }) => {
  const searchParams = new URLSearchParams(window.location.search);

  const updatedQuery = {
    ...qs.parse(searchParams.toString()),
    ...query,
  }

  return qs.stringify(updatedQuery, { addQueryPrefix: true });
};
