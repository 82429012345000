import { TrustBox } from "components";
import { GrowingIcon } from "./TrustGrowingIcon";

export function TrustGrowing() {
  return (
    <TrustBox icon={<GrowingIcon />}>
      Serving
      <span className="font-bold"> 10,000+ </span>
      renters and growing
    </TrustBox>
  );
}
