import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { setAxiosAuth } from 'utils';
import { commonApi } from 'core/api/common.api';

export interface TrackPortalProps {
  accessToken?: string;
}

export const useTrackPortal = ({
  accessToken = '',
}: TrackPortalProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.trackPortal],
    queryFn: commonApi.getPortalInfo,
    staleTime: 1000 * 60 * 1,
  });
}
