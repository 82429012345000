import { useEffect, useRef } from "react";

export function useWindowsResize(callback: (evt: UIEvent) => void) {
  const cbRef = useRef(callback);

  cbRef.current = callback;

  useEffect(() => {
    const cb = (evt: UIEvent) => {
      cbRef.current(evt)
    };

    window.addEventListener('resize', cb, false);

    return () => {
      window.removeEventListener('resize', cb, false);
    }
  }, [])
}
