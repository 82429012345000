import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../config/tailwind.config';

type CustomConfig = typeof tailwindConfig

// @ts-ignore
const { theme } = resolveConfig<CustomConfig>(tailwindConfig);

const breakpoints = theme.screens;

export type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
