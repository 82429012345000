import { DateInput, DateInputProps } from "./DateInput";

export interface DateInputsProps {
  pickupInputProps: DateInputProps;
  returnInputProps: DateInputProps;
}

export const DateInputs = ({ pickupInputProps, returnInputProps }: DateInputsProps) => {
  return (
    <>
      <DateInput {...pickupInputProps} />
      <DateInput {...returnInputProps} />
    </>
  );
}
