
import { useMutation } from '@tanstack/react-query';
import { promoApi } from 'core/api/promo.api';
import { setAxiosAuth } from 'utils';

export const useRecordPromo = ({ accessToken = '' }) => {
  setAxiosAuth(accessToken);

  return useMutation({
    mutationFn: promoApi.recordPromoRequest,
    retry: 0,
  });
}
