import { TrustBox } from "components";
import { TrustBookingIcon } from "./TrustBookingIcon";

export function TrustBooking() {
  return (
    <TrustBox icon={<TrustBookingIcon />}>
      Free
      <span className="font-bold"> flexible changes </span>
      on most bookings
    </TrustBox>
  );
}
