import { TrustBox } from "components";
import { TrustSupportIcon } from "./TrustSupportIcon";

export function TrustSupport() {
  return (
    <TrustBox icon={<TrustSupportIcon />}>
      Leading
      <span className="font-bold"> customer support </span>
      on every rental
    </TrustBox>
  );
}
