import { TrustBox } from "components";
import { TrustTrailersIcon } from "./TrustTrailersIcon"

export function TrustTrailers() {
  return (
    <TrustBox icon={<TrustTrailersIcon />}>
      <span className="font-bold"> 200+ </span>
      trusted companies  and trailers available
    </TrustBox>
  );
}
