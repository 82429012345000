import { useEffect, useRef } from 'react';
import { addToOpennedTabIds, removeOpennedTabId } from 'utils';

export const useOpennedTabIds = () => {
  const tabIdRef = useRef(Math.random().toString(16).slice(2));

  useEffect(() => {
    addToOpennedTabIds(tabIdRef.current);

    const removeOpenned = () => {
      removeOpennedTabId(tabIdRef.current);
    };

    window.addEventListener('beforeunload', removeOpenned, false);

    return () => {
      window.removeEventListener('beforeunload', removeOpenned, false);
    }
  }, []);
};
