
import dayjs from 'dayjs';
import { useGetAccessToken, useGetAssetCalendar } from './api';
import { useMemo, useRef } from 'react';

export const useGetCalendarInfo = (assetId: string) => {
  const currentDateRef = useRef(dayjs());

  const { data: accessTokenData } = useGetAccessToken();
  const { data: assetCalendarData, isLoading } = useGetAssetCalendar({
    assetId,
    pickupDate: currentDateRef.current.startOf('month').format('YYYY-MM-DD'),
    returnDate: currentDateRef.current.add(12, 'month').endOf('month').format('YYYY-MM-DD'),
    accessToken: accessTokenData?.data?.accessToken,
  });

  const availability: {[key: string]: boolean} = useMemo(() => assetCalendarData?.data?.availability || {}, [assetCalendarData]);
  const excludeDates = useMemo(() => (
    Object.entries(availability).reduce((acc: Date[], [dateString, isAvailable]) => {
      if (!isAvailable) {
        acc.push(new Date(`${dateString} 00:00:00`));
      }
      return acc;
    }, [])
  ), [availability]);

  return useMemo(() => ({
    isLoading,
    excludeDates,
    assetCalendarData,
    availability,
  }), [isLoading, excludeDates, assetCalendarData, availability]);
}
