import { PropsWithChildren } from "react";

export const noDateLabel = 'Select Date';

export function DateLabel({ children }: PropsWithChildren) {
  return (
    <div className="w-2/4">
      {Boolean(children) ? children : <span className="text-gray-500">{noDateLabel}</span>}
    </div>
  );
}
