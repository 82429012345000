
import { useEffect, useRef, useState } from 'react';
import { getOpennedTabIds, getSpentNeededTimeStatus, removeSpentNeededTimeStatus, setSpendNeededTime } from 'utils';

export const useGetSpentNeededTimeStatus = (minStayTimeSec = 30) => {
  const [spentTimeStatus, setSpentTimeStatus] = useState(false);
  const timeSpentRef = useRef(0);
  const loopTimeoutIdRef = useRef<NodeJS.Timeout>(undefined);

  useEffect(() => {
    const timer = () => {
      const spendEnoughTime = getSpentNeededTimeStatus();

      if (spendEnoughTime === 'true') {
        setSpentTimeStatus(true);
        return;
      }

      timeSpentRef.current += 1;

      if (timeSpentRef.current > minStayTimeSec) {
        setSpentTimeStatus(true);
        setSpendNeededTime();
        return;
      }

      loopTimeoutIdRef.current = setTimeout(timer, 1000);
    }

    timer();

    return () => {
      clearTimeout(loopTimeoutIdRef.current);
    }
  }, [minStayTimeSec]);

  useEffect(() => {
    const removeSpentTimeStatus = () => {
      if (!getOpennedTabIds().length) {
        removeSpentNeededTimeStatus();
      }
    };

    window.addEventListener('beforeunload', removeSpentTimeStatus, false);

    return () => {
      window.removeEventListener('beforeunload', removeSpentTimeStatus, false);
    }
  }, []);

  return spentTimeStatus;
};
