import { useEffect, useState } from "react";


export const useIsBrowserTabActive = () => {
  const [isBrowserTabActive, setIsBrowserTabActive] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsBrowserTabActive(() => document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    }
  }, []);

  return isBrowserTabActive;
};
