import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { setAxiosAuth } from 'utils';
import { locationApi } from 'core/api/locations.api';

export interface CitiesProps {
  cityName?: string;
  pageNum?: number;
  pageCount?: number;
  accessToken?: string;
  staleTime?: UseQueryOptions<any>['staleTime'];
  enabled?: boolean;
}

export const useGetCities = ({
  cityName,
  pageNum = 1,
  pageCount = 10,
  accessToken = '',
  staleTime = 1000 * 60 * 5,
  enabled = true,
}: CitiesProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.cities, cityName, pageNum, pageCount],
    queryFn: () => locationApi.getCities(cityName || '', pageNum, pageCount),
    staleTime,
    enabled: Boolean(accessToken && cityName) && enabled,
  });
}
