import { useContext, useEffect, useMemo, useRef } from "react";
import { useGetAccessToken, useGetCities } from "./api";
import { useDispatch } from "react-redux";
import { SettingContext } from "core/context/SettingContext";
import { useSearchParams } from "react-router-dom";
import { menu_trailers } from "core/constants/menu";
import { setFilterCategory } from "core/store/slices/settingSlice";

export const useSetSearchQueryString = () => {
  const searchCityRef = useRef('');

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { location, setLocation } = useContext(SettingContext);
  const { data: accessTokenData } = useGetAccessToken();

  const accessToken = accessTokenData?.data?.accessToken;
  const queryStringType = searchParams.get('type') || '';
  const queryStringCity = searchParams.get('city') || '';
  const localCityName: string = location?.obj?.name || '';

  useEffect(() => {
    if (queryStringCity && localCityName !== queryStringCity && !searchCityRef.current) {
      searchCityRef.current = queryStringCity;
    }
  }, [queryStringCity, localCityName]);

  const {
    data: citiesData,
    isLoading: citiesIsLoading,
  } = useGetCities({
    cityName: searchCityRef.current,
    accessToken,
    staleTime: Infinity,
  });

  useEffect(() => {
    const city = citiesData?.data?.cities?.[0];

    if (
      !city?.name || (
        city?.lat === location?.lat &&
        city?.lng === location?.lng
      )
    ) { return; }

    setLocation({
      label: city?.name,
      obj: { lat: city?.lat, lng: city?.lng, name: city?.name },
      userInput: true,
    });
  }, [citiesData, location?.lat, location?.lng, setLocation]);

  useEffect(() => {
    const category = queryStringType.trim().toLowerCase();

    if (!category) { return; }

    const selOption = menu_trailers?.find(
      (option:any) => option.lookup.trim().toLowerCase() === category
    );
    if(selOption) {
      dispatch(setFilterCategory(selOption.value));
    }
  }, [dispatch, queryStringType]);

  return useMemo(() => ({
    citiesIsLoading,
  }), [citiesIsLoading]);
};
