import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { setAxiosAuth } from 'utils';
import { commonApi } from 'core/api/common.api';

export interface ClientIpInfoProps {
  accessToken?: string;
}

export const useClientIpInfo = ({
  accessToken = '',
}: ClientIpInfoProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.clientIpInfo],
    queryFn: commonApi.getClientIPInfo,
    staleTime: 1000 * 60 * 1,
  });
}
