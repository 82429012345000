import React from 'react';
import httpService from '../api/httpService';
import dayjs from 'dayjs';
import { dayjsifyFilterDate, setFilterDates } from 'utils';
import { useGetAccessToken } from 'hooks/api';
import { useMatch } from 'react-router-dom';
import { productSubCategories } from 'core/constants/products';
import { COOKIE_SETTING } from 'core/constants/constant';

interface ContextData {
  token: string;
}

const productSubCat = Object.keys(productSubCategories?.trailers);

const AuthContext = React.createContext({} as ContextData);

export default AuthContext;

export function AuthProvider({ children }: any) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const productMatch = useMatch('/trailer-rentals/:subcategory/:assetId');

  const waitForToken = Boolean(
    productMatch
    && productSubCat?.includes?.(productMatch.params?.subcategory || '')
  );

  const { data, failureReason } = useGetAccessToken();

  const accessToken = data?.data?.accessToken;

  React.useEffect(() => {
    if (failureReason?.message) {
      console.log(failureReason?.message);
    }
  }, [failureReason?.message]);

  React.useEffect(() => {
    if (!accessToken) return;

    localStorage.setItem('TOKEN', accessToken);
    httpService.setAccessToken();
    setIsLoaded(true);
  }, [accessToken]);

  React.useLayoutEffect(() => {
    const settingInfo = JSON.parse(window.localStorage.getItem(COOKIE_SETTING) ?? '{}');
    const localFilterDate = settingInfo.dateFilter || {};

    if (localFilterDate?.pickupDate) {
      if (dayjsifyFilterDate(localFilterDate?.pickupDate).isBefore(dayjs().startOf('day'))) {
        localFilterDate.pickupDate = undefined;
        localFilterDate.pickupTime = undefined;
      }
    }

    if (localFilterDate?.returnDate) {
      if (dayjsifyFilterDate(localFilterDate?.returnDate).isBefore(dayjs().startOf('day'))) {
        localFilterDate.returnDate = undefined;
        localFilterDate.returnTime = undefined;
      }
    }

    setFilterDates(localFilterDate);
  }, []);

  const contextData: ContextData = {
    token: accessToken,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {isLoaded || !waitForToken ? children : null}
    </AuthContext.Provider>
  );
}
