import { useQuery } from '@tanstack/react-query';
import { assetsApi } from 'core/api';
import { queryKeys } from '../queryKeys';
import { setAxiosAuth } from 'utils';

export interface AssetCalendarProps {
  assetId?: string;
  pickupDate?: string;
  pickupTime?: string;
  returnDate?: string;
  returnTime?: string;
  accessToken?: string;
  enabled?: boolean;
}

export const useGetAssetCalendar = ({
  assetId = '',
  pickupDate = '',
  pickupTime = '12:00',
  returnDate = '',
  returnTime = '12:00',
  accessToken = '',
  enabled = true,
}: AssetCalendarProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.assetCalendar, assetId, pickupDate, pickupTime,returnDate, returnTime],
    queryFn: async () => await assetsApi.getAssetCalendar(assetId, pickupDate, pickupTime,returnDate, returnTime),
    enabled: Boolean(assetId) && Boolean(accessToken) && enabled,
    staleTime: 1000 * 60 * 5,
  });
}
