import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const rangeHasDisabledDate = (
  pickupDate?: Date,
  returnDate?: Date,
  disabledDates?: Date[],
) => {
  if (!pickupDate || !returnDate || !disabledDates?.length) {
    return false;
  }

  return disabledDates.some(
    (disabled) => dayjs(disabled).isBetween(dayjs(pickupDate).subtract(1, 'day'), dayjs(returnDate).add(1, 'day'))
  );
}
