import http from './httpService';

class LocationApi {
  async getCities(cityName: string, pageNum = 1, pageCount = 10) {
    const fetchURL = `${process.env.REACT_APP_BASE_URL ?? ''}location/city/list?pageCount=${pageCount}&pageNum=${pageNum}&searchFilter=${cityName}`;

    return await http.get(fetchURL);
  }

  async getLocation(id: number) {
    return await http.get(`${process.env.REACT_APP_BASE_URL ?? ''}location/${id}`);
  }

  async getLocationHours(id: number) {
    return await http.get(`${process.env.REACT_APP_BASE_URL ?? ''}location/${id}/hour`);
  }

  async getLocations(status = 'ACTIVE', returnMaster = '', searchText = '', sortOrder = { sort: '', order: '' }) {
    const sortField = sortOrder.sort.toUpperCase() || '';
    const orderType = sortOrder.order || '';
    return await http.get(`${process.env.REACT_APP_BASE_URL ?? ''}location/list?status=${status}&sort=${sortField}&sortOrder=${orderType}&searchFilter=${searchText}&returnMaster=${returnMaster}`);
  }
}

export const locationApi = new LocationApi();
