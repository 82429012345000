import React from 'react';

export interface TrustBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
}

export function TrustBox({ icon, className, children, ...rest }: TrustBoxProps) {
  return (
    <div
      className={`select-none flex flex-col items-center ${className}`}
      {...rest}
    >
      {icon}
      <p className="max-w-185 text-center drop-shadow-md pt-8 m-0">
        {children}
      </p>
    </div>
  );
}
