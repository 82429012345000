import losAnglesImg from './assets/images/los-angles.webp';
import longBeachCaImg from './assets/images/long-beach-ca.webp';
import inlandEmpireCaImg from './assets/images/inland-empire-ca.webp';
import venturaCaImg from './assets/images/ventura-ca.webp';
import hendersonNvImg from './assets/images/henderson-nv.webp';
import losVegasImg from './assets/images/los-vegas.webp';
import renoImg from './assets/images/reno.webp';
import glendaleAzImg from './assets/images/glendale-az.webp';
import phoenixAzImg from './assets/images/phoenix-az.webp';
import dallasImg from './assets/images/dallas.webp';
import fortWorthTxImg from './assets/images/fort-worth-tx.webp';
import planoTxImg from './assets/images/plano-tx.webp';
import atlantaGaImg from './assets/images/atlanta-ga.webp';
import denverCoImg from './assets/images/denver-co.webp';
import boiseImg from './assets/images/boise.webp';
import birminghamAlImg from './assets/images/birmingham-al.webp';
import hamptonNhImg from './assets/images/hampton-nh.webp';
import oklahomaCityOkImg from './assets/images/oklahoma-city-ok.webp';
import columbiaScImg from './assets/images/columbia-sc.webp';
import { CityType } from './types';

// TODO: All cities should be in an object, returning by a utility.
export const cities: CityType[] = [
  // California
  {
    state: 'California',
    stateShort: 'CA',
    name: 'Los Angeles',
    image: losAnglesImg,
    path: '/california/los-angeles',
    lat: 34.054908,
    lng: -118.242643,
    active: true,
  },
  {
    state: 'California',
    stateShort: 'CA',
    name: 'Long Beach',
    image: longBeachCaImg,
    path: '/california/long-beach',
    lat: 33.77005,
    lng: -118.193739,
    active: true,
  },
  {
    state: 'California',
    stateShort: 'CA',
    name: 'Riverside',
    image: inlandEmpireCaImg,
    path: '/california/riverside',
    lat: 33.980601 ,
    lng: -117.375494,
    active: true,
  },
  {
    state: 'California',
    stateShort: 'CA',
    name: 'Ventura',
    image: venturaCaImg,
    path: '/california/ventura',
    lat: 34.280492,
    lng: -119.29452,
    active: true,
  },
  // Nevada
  {
    state: 'Nevada',
    stateShort: 'NV',
    name: 'Henderson',
    image: hendersonNvImg,
    path: '/nevada/henderson',
    lat: 36.039525,
    lng: -114.981721,
    active: true,
  },
  {
    state: 'Nevada',
    stateShort: 'NV',
    name: 'Las Vegas',
    image: losVegasImg,
    path: '/nevada/las-vegas',
    lat: 36.171563,
    lng: -115.139101,
    active: true,
  },
  {
    state: 'Nevada',
    stateShort: 'NV',
    name: 'Reno',
    image: renoImg,
    path: '/nevada/reno',
    lat: 39.529919,
    lng: -119.814269,
    active: true,
  },
  // Arizona
  {
    state: 'Arizona',
    stateShort: 'AZ',
    name: 'Glendale',
    image: glendaleAzImg,
    path: '/arizona/glendale',
    lat: 33.538652,
    lng: -112.185986,
    active: true,
  },
  {
    state: 'Arizona',
    stateShort: 'AZ',
    name: 'Phoenix',
    image: phoenixAzImg,
    path: '/arizona/phoenix',
    lat: 33.448376,
    lng: -112.074036,
    active: true,
  },
  // Texas
  {
    state: 'Texas',
    stateShort: 'TX',
    name: 'Dallas',
    image: dallasImg,
    path: '/texas/dallas',
    lat: 32.7673,
    lng: -96.7776,
    active: true,
  },
  {
    state: 'Texas',
    stateShort: 'TX',
    name: 'Fort Worth',
    image: fortWorthTxImg,
    path: '/texas/fort-worth',
    lat: 32.755488,
    lng: -97.330766,
    active: true,
  },
  {
    state: 'Texas',
    stateShort: 'TX',
    name: 'Plano',
    image: planoTxImg,
    path: '/texas/plano',
    lat: 33.019843,
    lng: -96.698886,
    active: true,
  },
  // Georgia
  {
    state: 'Georgia',
    stateShort: 'GA',
    name: 'Atlanta',
    image: atlantaGaImg,
    path: '/georgia/atlanta',
    lat: 33.749,
    lng: -84.388,
    active: true,
  },
  // Clorado
  {
    state: 'Cloardo',
    stateShort: 'CO',
    name: 'Denver',
    image: denverCoImg,
    path: '/colorado/denver',
    lat: 39.739,
    lng: -104.990,
    active: true,
  },
  // Idaho
  {
    state: 'Idaho',
    stateShort: 'ID',
    name: 'Boise',
    image: boiseImg,
    path: '/idaho/boise',
    lat: 43.615019,
    lng: -116.202314,
    active: true,
  },
  // Alabama
  {
    state: 'Alabama',
    stateShort: 'AL',
    name: 'Birmingham',
    image: birminghamAlImg,
    path: '/alabama/birmingham',
    lat: 33.518589,
    lng: -86.810356,
    active: true,
  },
  // New Hampshire
  {
    state: 'New Hampshire',
    stateShort: 'NH',
    name: 'Hampton',
    image: hamptonNhImg,
    path: '/new-hampshire/hampton',
    lat: 42.9359,
    lng: -70.8243,
    active: true,
  },
  // Oklahoma
  {
    state: 'Oklahoma',
    stateShort: 'OK',
    name: 'Oklahoma City',
    image: oklahomaCityOkImg,
    path: '/oklahoma/oklahoma-city',
    lat: 35.4676,
    lng: -97.5164,
    active: true,
  },
  // South Carolina
  {
    state: 'South Carolina',
    stateShort: 'SC',
    name: 'Columbia',
    image: columbiaScImg,
    path: '/south-carolina/columbia',
    lat: 34.0007,
    lng: -81.0348,
    active: false,
  },
];
