import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { addVisitedPathname, getOpennedTabIds, removeVisitedPathnames } from 'utils';

export const useVisitedPathnames = () => {
  const location = useLocation();

  useEffect(() => {
    addVisitedPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const removePathnames = () => {
      if (!getOpennedTabIds().length) {
        removeVisitedPathnames();
      }
    };

    window.addEventListener('beforeunload', removePathnames, false);

    return () => {
      window.removeEventListener('beforeunload', removePathnames, false);
    }
  }, []);
};
