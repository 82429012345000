import { lazy } from 'react';
import type { IRouteItemProp } from './interfaces/base';
import { serviceStates } from '../core/data/city';

const HomePage = lazy(() => import('pages/HomePage'));
const MapPage = lazy(() => import('pages/Map'));
const TrailersLocationState = lazy(() => import('pages/ServiceArea/state'));
const Trailers = lazy(() => import('pages/LandingPage/trailers'));
const AllProducts = lazy(() => import('pages/AllProducts'));
const FlatbedTrailers = lazy(() => import('pages/LandingPage/trailers/flatbed'));
const EnclosedTrailers = lazy(() => import('pages/LandingPage/trailers/enclosed'));
const EquipmentTrailers = lazy(() => import('pages/LandingPage/trailers/equipment'));
const RollOffDumpTrailers = lazy(() => import('pages/LandingPage/trailers/rollOfDump'));
const GooseneckTrailers = lazy(() => import('pages/LandingPage/trailers/gooseneck'));
const UtilityTrailers = lazy(() => import('pages/LandingPage/trailers/utility'));
const TiltTrailers = lazy(() => import('pages/LandingPage/trailers/tilt'));
const DumpTrailers = lazy(() => import('pages/LandingPage/trailers/dump'));
const Results = lazy(() => import('pages/ProductDetail/Results'));
const ProductDetail = lazy(() => import('pages/ProductDetail'));
const ServiceArea = lazy(() => import('pages/ServiceArea'));
const Blog = lazy(() => import('pages/Blog'));
const BlogArticleHomeProjects = lazy(() => import('pages/Blog/TrailerForHomeProjects/TrailerForHomeProjects'));
const BlogArticleUnluckTrailerPotential = lazy(() => import('pages/Blog/CompleteGuidetoRentingTrailer/CompleteGuidetoRentingTrailer'));
const BlogArticleRoutineTrailerMaintenance = lazy(() => import('pages/Blog/GuideForRoutineMaintenance/GuideForRoutineMaintenance'));
const BlogArticleEssentialTipsForTowingSafely = lazy(() => import('pages/Blog/TowingATrailerSafely/TowingATrailerSafely'));
const BlogArticleEssentialTipsForLongDistance = lazy(() => import('pages/Blog/PreparingForLongDistanceTravel/PreparingForLongDistanceTravel'));
const LearningHowToBackupTrailerPro = lazy(() => import('pages/Blog/LearningHowToBackupTrailerPro/LearningHowToBackupTrailerPro'));
const HowToDraftTrailerRentalAgreement = lazy(() => import('pages/Blog/HowToDraftTrailerRentalAgreement/HowToDraftTrailerRentalAgreement'));
const HowToLoadTrailerProStepByStep = lazy(() => import('pages/Blog/HowToLoadTrailerProStepByStep/HowToLoadTrailerProStepByStep'));
const InspectingTrailerBeforeTowGuide = lazy(() => import('pages/Blog/InspectingTrailerBeforeTowGuide/InspectingTrailerBeforeTowGuide'));
const IndustrySolution = lazy(() => import('pages/IndustrySolution'));
const Checkout = lazy(() => import('pages/CheckOut'));
const Promotion = lazy(() => import('pages/Promotion'));
const ConfirmPage = lazy(() => import('pages/CheckOut/confirmPage'));
const PrivacyPolicy = lazy(() => import('pages/privacyPage'));
const TermsPolicy = lazy(() => import('pages/termPage'));
const ContactUS = lazy(() => import('pages/ContactUs'));
const FAQPage = lazy(() => import('pages/faq'));
const PartnerPage = lazy(() => import('pages/partner'));
const PartnerRegistrationPage = lazy(() => import('pages/PartnerRegistration'))

export const getRoutes = () => {
  const routeItems: IRouteItemProp[] = [
  {
    path: '/',
    element: <HomePage />,
    id: 'home',
    sitemap: {
      changefreq: 'weekly',
      priority: '1.0',
    },
  },
  {
    path: '/trailer-rentals/map',
    element: <MapPage />,
    id: 'map',
    sitemap: {
      changefreq: 'weekly',
      priority: '0.6',
    },
  },
  {
    path: '/trailer-rentals/locations',
    element: <ServiceArea />,
    id: 'servicearea',
    sitemap: {
      changefreq: 'weekly',
      priority: '0.8',
    },
  },
  {
    path: '/trailer-rentals',
    element: <Trailers />,
    id: 'trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.7',
    },
  },
  {
    path: '/trailer-rentals/results',
    element: <AllProducts />,
    id: 'product',
    sitemap: {
      changefreq: 'daily',
      priority: '0.9',
    },
  },
  {
    path: '/trailer-rentals/flatbed-trailers',
    element: <FlatbedTrailers />,
    id: 'flatbed-trailers',
  },
  {
    path: '/trailer-rentals/enclosed-trailers',
    element: <EnclosedTrailers />,
    id: 'enclosed-trailers',
  },
  {
    path: '/trailer-rentals/equipment-trailers',
    element: <EquipmentTrailers />,
    id: 'equipment-trailers',
  },
  {
    path: '/trailer-rentals/roll-off-dump-trailers',
    element: <RollOffDumpTrailers />,
    id: 'roll-off-dump-trailers',
  },
  {
    path: '/trailer-rentals/gooseneck-trailers',
    element: <GooseneckTrailers />,
    id: 'gooseneck-trailers',
  },
  {
    path: '/trailer-rentals/utility-trailers',
    element: <UtilityTrailers />,
    id: 'utility-trailers',
  },
  {
    path: '/trailer-rentals/tilt-trailers',
    element: <TiltTrailers />,
    id: 'tilt-trailers',
  },
  {
    path: '/trailer-rentals/dump-trailers',
    element: <DumpTrailers />,
    id: 'dump-trailers',
  },
  {
    path: '/:category/results/:assetId',
    element: <Results />,
    id: 'equip',
  },
  {
    path: '/trailer-rentals/:subcategory/:assetId',
    element: <ProductDetail />,
    id: 'proddetail',
  },
  {
    path: '/rental-resources',
    element: <Blog />,
    id: 'rental-resources',
    sitemap: {
      changefreq: 'weekly',
      priority: '0.5',
    },
  },
  {
    path: '/rental-resources/trailer-renters/essential-guide-renting-trailer-home-improvement',
    element: <BlogArticleHomeProjects />,
    id: 'resource-home-improvement',
  },
  {
    path: '/rental-resources/trailer-owners/unlock-potential-trailer-complete-guide-renting-your-trailer',
    element: <BlogArticleUnluckTrailerPotential />,
    id: 'resource-unlock-renting',
  },
  {
    path: '/rental-resources/trailer-owners/ultimate-guide-routine-trailer-maintenance',
    element: <BlogArticleRoutineTrailerMaintenance />,
    id: 'resource-maintenance-guid',
  },
  {
    path: '/rental-resources/trailer-safety/essential-tips-towing-trailer-safely',
    element: <BlogArticleEssentialTipsForTowingSafely />,
    id: 'resource-towing-safely',
  },
  {
    path: '/rental-resources/trailer-tips/six-essential-tips-preparing-trailer-long-distance-travel',
    element: <BlogArticleEssentialTipsForLongDistance />,
    id: 'resource-long-distance',
  },
  {
    path: '/rental-resources/trailer-safety/learning-how-to-backup-trailer-pro',
    element: <LearningHowToBackupTrailerPro />,
    id: 'backup-trailer-pro',
  },
  {
    path: '/rental-resources/trailer-owners/starting-points-how-to-draft-trailer-rental-agreement',
    element: <HowToDraftTrailerRentalAgreement />,
    id: 'draft-trailer-rental-agreement'
  },
  {
    path: '/rental-resources/trailer-safety/how-to-load-trailer-pro-step-by-step',
    element: <HowToLoadTrailerProStepByStep />,
    id: 'how-to-lead-trailer-step-by-step'
  },
  {
    path: '/rental-resources/trailer-safety/inspecting-trailer-before-tow-guide',
    element: <InspectingTrailerBeforeTowGuide />,
    id: 'how-to-lead-trailer-step-by-step'
  },
  {
    path: '/industry-solution',
    element: <IndustrySolution />,
    id: 'industry-solution',
  },
  {
    path: '/checkout',
    element: <Checkout />,
    id: 'checkout',
  },
  {
    path: '/promotion',
    element: <Promotion />,
    id: 'promotion',
  },
  {
    path: '/confirmation',
    element: <ConfirmPage />,
    id: 'confirmation',
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    id: 'privacy-policy',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/terms-service',
    element: <TermsPolicy />,
    id: 'terms-service',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/contact-us',
    element: <ContactUS />,
    id: 'contact-us',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/faq',
    element: <FAQPage />,
    id: 'faq',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/partner/:identifier',
    element: <PartnerPage />,
    id: 'partner',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path : '/partner-registration',
    element: <PartnerRegistrationPage />,
    id: 'partner-registration',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  }
];

  serviceStates.forEach((state) => {
	  routeItems.push({
	    path: `${state?.path}/:city?`,
	    element: <TrailersLocationState />,
	    id: `trailers-${state?.name}`,
	    sitemap: {
	      changefreq: 'monthly',
	      priority: '0.5',
	      path: state?.path,
	    },})
  });

  return routeItems;
}