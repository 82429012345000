import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useGetAccessToken, useGetMapAssets, useGetListAssets } from "../api";
import { useSelector } from "react-redux";
import { AppState } from "core/store/store";
import { getDateFromMoment } from "core/constants/globalfunc";
import { SettingContext } from "core/context/SettingContext";
import { UseGetAssetsProps } from "../api/get-assets-options/types";
import { AxiosResponse } from "axios";
import { SortType } from "./types";

export const useSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [sort, setSort] = useState<SortType>();
  const mapData = useRef<AxiosResponse<any, any>>(undefined);
  const timeoutIdRef = useRef<NodeJS.Timeout>(undefined);
  const listLoadingStarted = useRef(false);

  const filterDate = useSelector((state: AppState) => state.setting.filterDate);
  const filterCategory = useSelector((state: AppState) => state.setting.filterCategory);
  const { location } = useContext(SettingContext);

  const debouncedIsLoadingFalse = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      setIsLoading(() => false);
    }, 10 * 1000);
  }, [])

  const { data: accessTokenData } = useGetAccessToken();

  const accessToken = accessTokenData?.data?.accessToken;

  const query: UseGetAssetsProps = {
    pickupDate: getDateFromMoment(filterDate?.pickupDate),
    returnDate: getDateFromMoment(filterDate?.returnDate),
    lat: location?.obj?.lat || '' as string,
    lng: location?.obj?.lng || '' as string,
    sort: sort?.sort || 'BEST_MATCH',
    sortOrder: sort?.sortOrder || 'ASC',
    accessToken,
    assetIds,
    assetTypeId: filterCategory || '',
  }

  const {
    data: mapAssetsData,
    isLoading: mapAssetsIsLoading,
    isError: mapAssetsIsError,
  } = useGetMapAssets(query);

  useEffect(() => {
    if (mapAssetsData) {
      mapData.current = mapAssetsData;
    } else {
      debouncedIsLoadingFalse();
    }
  }, [mapAssetsData, debouncedIsLoadingFalse]);

  useEffect(() => {
    if (mapAssetsIsLoading) {
      setIsLoading(() => true);
    }
  }, [mapAssetsIsLoading]);

  const {
    data: listAssetsData,
    isLoading: listAssetsIsLoading,
    isError: listAssetsIsError,
  } = useGetListAssets({
    ...query,
    assetIds,
    enabled: Boolean(assetIds?.length) && !mapAssetsIsLoading,
  });

  useEffect(() => {
    if (listAssetsIsLoading) {
      listLoadingStarted.current = true;
      clearTimeout(timeoutIdRef.current);
      setIsLoading(() => true);
      return;
    }
    if (isLoading && listLoadingStarted.current && !listAssetsIsLoading) {
      listLoadingStarted.current = false;
      clearTimeout(timeoutIdRef.current);
      setIsLoading(() => false);
    }
  }, [isLoading, listAssetsIsLoading]);

  useEffect(() => {
    if (mapAssetsIsError || listAssetsIsError) {
      listLoadingStarted.current = false;
      clearTimeout(timeoutIdRef.current);
      setIsLoading(() => false);
    }
  }, [mapAssetsIsError, listAssetsIsError]);

  return useMemo(() => ({
    isLoading,
    mapAssetsData: mapAssetsData === undefined && mapAssetsIsLoading ? mapData.current : mapAssetsData,
    listAssetsData,
    mapAssetsIsLoading,
    listAssetsIsLoading,
    setAssetIds,
    setSort,
  }), [
    isLoading,
    mapAssetsData,
    listAssetsData,
    mapAssetsIsLoading,
    listAssetsIsLoading,
    setAssetIds,
    setSort,
  ]);
};
