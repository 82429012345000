import { useEffect, useRef } from "react";


export const useClickOutside = (callback: Function) => {
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  useEffect(() => {
    const handleClickOutside = (evt: MouseEvent) => {
      callbackRef.current?.(evt);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
}
