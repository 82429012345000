import { useEffect } from 'react';
import { getOpennedTabIds, removeSearchHasResult } from 'utils';

export const useSearchHasResult = () => {
  useEffect(() => {
    const removeQuoteStatus = () => {
      if (!getOpennedTabIds().length) {
        removeSearchHasResult();
      }
    };

    window.addEventListener('beforeunload', removeQuoteStatus, false);

    return () => {
      window.removeEventListener('beforeunload', removeQuoteStatus, false);
    }
  }, []);
};
