
import { InputHTMLAttributes } from 'react';
import { noDateLabel } from './DateLabel';
import { useBreakpoint } from 'hooks';
import classNames from 'classnames';

export type DateInputProps = InputHTMLAttributes<HTMLInputElement>;

export const DateInput = ({ className, ...rest }: DateInputProps) => {
  const { isLg } = useBreakpoint('lg');

  return (
    <input
      className={classNames('w-2/4 border-0 outline-none bg-transparent', className)}
      placeholder={noDateLabel}
      autoComplete='off'
      readOnly={!isLg}
      {...rest}
    />
  );
}
