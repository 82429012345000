import React from 'react';
import { Popover, PopoverProps } from "antd";
import { useClickOutside } from 'hooks';

export interface PopperRef {
  popupRef: {
    current: {
      getElement: Function;
    };
  };
  triggerRef: {
    current: HTMLElement;
  };
}

export interface CalendarPopperProps extends PopoverProps {
  onClose?: Function;
}

export function CalendarPopper({
  overlayStyle,
  overlayInnerStyle,
  onClose,
  ...rest
}: CalendarPopperProps) {
  const tooltipContainerRef = React.useRef<HTMLElement>(undefined);

  useClickOutside((evt: MouseEvent) => {
    const target = evt.target as HTMLElement;
    const triggerEl = tooltipContainerRef.current;

    if (
      triggerEl
      && !triggerEl.contains(target)
    ) {
      onClose?.();
    }
  });

  return (
    <Popover
      getTooltipContainer={(container: HTMLElement) => {
        tooltipContainerRef.current = container;
        return container;
      }}
      showArrow={false}
      overlayStyle={{
        boxShadow: 'none',
        background: 'transparent',
        overflow: 'visible',
        ...overlayStyle,
      }}
      overlayInnerStyle={{
        borderRadius: 20,
        padding: 10,
        ...overlayInnerStyle,
      }}
      {...rest}
    />
  );
}
