import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { setFilterDates } from 'utils';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export function SearchRangePicker(props: RangePickerProps) {
  const filterDate = useSelector((state: any) => state.setting.filterDate);

  const handleChange = (dates: any, dateStrings: [string, string]) => {
    if (dateStrings[0] && dateStrings[1]) setFilterDates({ ...filterDate, pickupDate: dateStrings[0], returnDate: dateStrings[1] });
    else if (dateStrings[0]) setFilterDates({ ...filterDate, pickupDate: dateStrings[0] });
    else if (dateStrings[1]) setFilterDates({ ...filterDate, returnDate: dateStrings[1] });
  };

  return (
    <RangePicker
      className='search-rangepicker'
      placeholder={['Select Date', 'Select Date']}
      format="MM/DD/YYYY"
      onChange={handleChange}
      value={[
        filterDate?.pickupDate ? dayjs(filterDate.pickupDate) : null,
        filterDate?.returnDate ? dayjs(filterDate.returnDate) : null,
      ]}
      {...props}
    />
  );
}
