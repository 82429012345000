import { RefObject, useEffect } from 'react';
import { useBreakpoint } from './useBreakpoint';

export const useScrollToId = (linksWrapperRef: RefObject<HTMLElement | null>, idsWrapperRef?: RefObject<HTMLElement | null>) => {
  const { isLg } = useBreakpoint('lg');

  useEffect(() => {
    if (!linksWrapperRef?.current) return;

    const links = linksWrapperRef.current.querySelectorAll('a[href^="#"]');
    const linkMap: { link: Element, clickHandler: EventListener }[] = [];

    links.forEach((link) => {
      const href = link.getAttribute('href');
      const target = (idsWrapperRef?.current || document.body)?.querySelector(`#${href?.substring(1)}`);
      if (target) {
        linkMap.push({
          link,
          clickHandler: (evt) => {
            evt.preventDefault();
            const { top } = target.getBoundingClientRect();
            window.scrollTo({
              top: window.scrollY + top - (isLg ? 150 : 100),
              left: 0,
              behavior: 'smooth',
            })
          }
        });
      }
    });

    linkMap.forEach(({ link , clickHandler }) => {
      link.addEventListener('click', clickHandler, false);
    });

    return () => {
      linkMap.forEach(({ link , clickHandler }) => {
        link.removeEventListener('click', clickHandler, false);
      });
    }
  }, [isLg, linksWrapperRef, idsWrapperRef]);
};
