import { useEffect, useState } from 'react';
// @ts-ignore
import ReactImagesViewer from 'react-images-viewer';

export interface ImagesViewerProps {
  images: {
    src: string;
    thumbnail: string;
    caption: string;
  }[];
  currentImageIndex: number;
  onClose?: (index?: number) => void;
}

const theme = {
  // container
  container: {
    background: 'rgba(255, 255, 255, .9)',
  },

  // arrows
  arrow: {
    'backgroundColor': 'rgba(255, 255, 255, .8)',
    'fill': '#222',
    'opacity': 0.6,
    'transition': 'opacity 200ms',

    ':hover': {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    'borderRadius': 40,
    'height': 40,
    'marginTop': -20,

    '@media (min-width: 768px)': {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 10 },
  arrow__direction__right: { marginRight: 10 },
  close: {
    'fill': '#d40000',
    'opacity': 0.6,
    'transition': 'all 200ms',
    ':hover': {
      opacity: 1,
    },
  },

  // footer
  footer: {
    color: '#000',
  },
  footerCount: {
    color: 'rgba(0, 0, 0, .6)',
  },

  // thumbnails
  thumbnail: {},
  thumbnail__active: {
    boxShadow: '0 0 0 2px #00d8ff',
  },
};

export function ImagesViewer({
  currentImageIndex = -1,
  onClose,
  images,
}: ImagesViewerProps) {
  const [currImgIndex, setCurrImgIndex] = useState(-1);

  useEffect(() => {
    setCurrImgIndex(() => currentImageIndex)
  }, [currentImageIndex]);

  const goToNext = () => setCurrImgIndex(
    (currIndex) => {
      const newIndex = currIndex + 1;
      return newIndex > images.length - 1 ? 0 : newIndex;
    }
  )

  const goToPrev = () => setCurrImgIndex(
    (currIndex) => {
      const newIndex = currIndex - 1;
      return newIndex < 0 ? images.length - 1 : newIndex;
    }
  )

  const goToImage = (index: number) => setCurrImgIndex(() => index);

  const close = () => {
    setCurrImgIndex(() => -1);
    onClose?.(currImgIndex);
  }

  return (
    <ReactImagesViewer
      backdropCloseable
      currImg={currImgIndex}
      imgs={images}
      isOpen={currImgIndex > -1}
      onClickImg={() => null}
      onClickNext={goToNext}
      onClickPrev={goToPrev}
      onClickThumbnail={goToImage}
      onClose={close}
      preventScroll={false}
      showThumbnails={true}
      theme={theme}
    />
  );
}
